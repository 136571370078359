import React from 'react';
import styled from 'styled-components';

import NavItems from './NavItems';

const MainReactObject = (props) => {
    return (
        <div style={{ backgroundColor: '#043A55', width: '100%'}}>
            <Navbar>
                <NavItems 
                    drawerToggleClicked={ props.drawerToggleClicked } 
                    lang={ props.lang }
                />
            </Navbar>
        </div>
    );
};

export default MainReactObject;

const Navbar = styled.nav`
    display: none;

    @media (min-width: 1000px) {
        height: 40px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        box-sizing: border-box;
        z-index: 90;    
    }
`;

