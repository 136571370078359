import React from 'react';

import classes from './NavItems.module.css';

import NavItem from './NavItem';
import DrawerToggle from './DrawerToggle';

const navItems = (props) => {
    return (
        <ul className={ classes.NavItems }>
            <DrawerToggle 
                clicked={ props.drawerToggleClicked } 
                lang={ props.lang }
            />
            <NavItem link="/news" active={ false }>{ props.lang === 'en' ? 'Tips Sharing' : '技術分享' }</NavItem>
            <li className={ classes.NavItem }>
                <a 
                    className={ props.active ? classes.active : null }
                    href="/#follow-us"
                >
                    { props.lang === 'en' ? 'Follow Us' : '關注我們' }
                </a>
            </li>
            <NavItem link="/faq" active={false}>{props.lang === 'en' ? 'FAQ' : 'FAQ'}</NavItem>
            {/* { props.isAuthenticated ? <NavigationItem link="/orders">Orders</NavigationItem> : null }
            { !props.isAuthenticated 
                ? <NavigationItem link="/auth">Authenticate</NavigationItem>
                : <NavigationItem link="/logout">Logout</NavigationItem>
            } */}
        </ul>
    );
};

export default navItems;