import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavItem.module.css';

const navItem = (props) => {
    return (
      <li className={ classes.NavItem }>
        <NavLink
            // smooth
            to={{
                pathname: props.link,
            }}
            exact
            activeClassName={ classes.active }
        >{ props.children }
        </NavLink>
            {/* <a 
                className={ props.active ? classes.active : null }
                href={ props.link }
            >
                { props.children }
            </a> */}
      </li>
    );
};

export default navItem;