import React from 'react';

import classes from './DrawerToggle.module.css';

const drawerToggle = (props) => {
    return (
        <li className={ classes.DrawerToggle } onClick={ props.clicked }>
            <div>{ props.lang === 'en' ? 'Categories' : '商品分類' }</div>
        </li>
    );
};

export default drawerToggle;