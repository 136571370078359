import React, { useState, useEffect, Suspense, useRef } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// import { Launcher } from '../../components/UI/ChatWindow/index';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import * as actions from '../../store/actions/index';
import SpinnerBackdrop from '../../components/UI/Spinner/SpinnerBackdrop';
import Spinner from '../../components/UI/Spinner/Spinner';

import { axiosHttp, parseUTCStrToLocalStr } from '../../helpers/base';
import { urlHostRest } from '../../helpers/url';
const Home = React.lazy(() => import('../../containers/Home/Home'));
const ProductList = React.lazy(() => import('../../containers/Product/ProductList'));
const ProductSingle = React.lazy(() => import('../../containers/Product/ProductSingle'));
const Login = React.lazy(() => import('../../containers/Login/Login'));
const Cart = React.lazy(() => import('../../containers/Cart/Cart'));
const MemberList = React.lazy(() => import('../../containers/Member/MemberList'));
const Register = React.lazy(() => import('../../containers/Member/Register'));
const ForgotPassword = React.lazy(() => import('../../containers/Member/ForgotPassword'));
const PayComplete = React.lazy(() => import('../Cart/PayComplete'));
const PayCompleteSinopac = React.lazy(() => import('../Cart/PayCompleteSinopac'));
const PaySinopac = React.lazy(() => import('../Cart/PaySinopac'));
const OrderList = React.lazy(() => import('../Order/OrderList'));
const OrderSingle = React.lazy(() => import('../Order/OrderSingle'));
const FavoriteList = React.lazy(() => import('../Product/FavoriteList'));
const PurchasedList = React.lazy(() => import('../Product/PurchasedList'));
const Contact = React.lazy(() => import('../../containers/Member/Contact'));
const NewsList = React.lazy(() => import('../../containers/News/NewsList'));
const NewsSingle = React.lazy(() => import('../../containers/News/NewsSingle'));
const RegisterPolicy = React.lazy(() => import('../../containers/Member/RegisterPolicy'));
const FaqList = React.lazy(() => import('../../containers/Faq/FaqList'));

const MainReactObject = (props) => {
    let history = useHistory();

    let showLayout = true;
    if (window.location.pathname.toLowerCase() == '/paysinopac') {
        showLayout = false;
    }
    
    const [stateViewDrawer, setViewDrawer] = useState(false);
    const [stateCartItemCount, setCartItemCount] = useState(0);
    const [stateFavoriteItemCount, setFavoriteItemCount] = useState(0);
    const [stateUpdateState, setUpdateState] = useState(1);
    const [stateUpdateFavorite, setUpdateFavorite] = useState(1);
    const [stateMessageOpen, setMessageOpen] = useState(false);
    const [stateMessagePage, setMessagePage] = useState(1);
    const [stateMessageList, setMessageList] = useState([]);
    const [stateMessageNewCount, setMessageNewCount] = useState(0);
    const refTimeoutId = useRef(null);

    // https://medium.com/geekculture/how-to-use-google-analytics-on-reactjs-in-5-minutes-7f6b43017ba9
    // ReactGA.initialize(gaPageviewTrackingId);
    // ReactGA.pageview(window.location.pathname);

    // const getMessageData = () => {
    //     let now = parseUTCStrToLocalStr(new Date().toISOString().split('.')[0].replace(/T/g, ' '));
    //     let twoYearsAgo = (parseInt(now.substr(0, 4)) - 2).toString() + now.substr(4);
    //     let sinceTime = stateMessageList.length > 0 ? stateMessageList[stateMessageList.length - 1].timeUTC : twoYearsAgo;

    //     if (!localStorage.getItem('token')) {
    //         return;
    //     }

    //     axios({
    //         method: 'post',
    //         url: urlHostRest + '/html/readHtmlByIdClientSinceTime',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //         },
    //         data: {
    //             idClient: props.idUser,
    //             sinceTime: sinceTime,
    //         },
    //     })
    //     .then((res) => {
    //         let listNew = [];
    //         for (let a in res.data.results) {
    //             if (stateMessageList.length === 0) {
    //                 var row = {
    //                     author: res.data.results[a].h_createdBy === parseInt(props.idUser) ? 'me' : 'them',
    //                     type: 'text',
    //                     data: {
    //                         text: res.data.results[a].content,
    //                     },
    //                     time: parseUTCStrToLocalStr(res.data.results[a].h_createdOn).substr(0, 16),
    //                     timeUTC: res.data.results[a].h_createdOn,
    //                 };
    //                 listNew.push(row);    
    //             }
    //             else if (res.data.results[a].h_createdBy !== parseInt(props.idUser)) {
    //                 if (!stateMessageOpen) {
    //                     let count = stateMessageNewCount + 1;
    //                     setMessageNewCount(count);
    //                 }
    //                 var row = {
    //                     author: res.data.results[a].h_createdBy === parseInt(props.idUser) ? 'me' : 'them',
    //                     type: 'text',
    //                     data: {
    //                         text: res.data.results[a].content,
    //                     },
    //                     time: parseUTCStrToLocalStr(res.data.results[a].h_createdOn).substr(0, 16),
    //                     timeUTC: res.data.results[a].h_createdOn,
    //                 };
    //                 listNew.push(row);    
    //             }
    //         }
    //         listNew = listNew.reverse();
    //         setMessageList(prev => {
    //             let arrReturn = [...prev, ...listNew];
    //             arrReturn.sort(sort_messageList);
    //             return arrReturn;
    //         });
    //     })
    //     .catch(() => {
    //         clearTimeout(refTimeoutId.current);
    //         refTimeoutId.current = null;
    //         return null;
    //     });
    // };

    useEffect(() => {
        props.onTryAutoSignin();
        props.onUpdateListCategory();
        props.onInitLang();
        // getMessageData();
    }, []);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            // if user is logged in because he has a token
            axiosHttp('post', '/order/readCartByIdUser', true, {})
            .then(res => {
                if (res.data.results.length > 0 && res.data.results[0].cart !== '') {
                    let productsDistinct = [];
                    let cart = res.data.results[0].cart;
                    let listItems = cart.split('-');
                    for (let a in listItems) {
                        let idProduct = listItems[a].split("@")[0];
                        let b = null;
                        for (b = 0; b < productsDistinct.length; b++) {
                            if (productsDistinct[b] === idProduct) {
                                break;
                            }
                        }
                        if (b === productsDistinct.length) {
                            productsDistinct.push(idProduct);
                        }
                    }
                    setCartItemCount(productsDistinct.length);    
                }
                else {
                    setCartItemCount(0);
                }
            });    
        }
        else if (localStorage.getItem('cart')) {
            // if user is not logged in but he has a cart
            let productsDistinct = [];
            let cart = localStorage.getItem('cart');
            let listItems = cart.split('-');
            for (let a in listItems) {
                let idProduct = listItems[a].split("@")[0];
                let b = null;
                for (b = 0; b < productsDistinct.length; b++) {
                    if (productsDistinct[b] === idProduct) {
                        break;
                    }
                }
                if (b === productsDistinct.length) {
                    productsDistinct.push(idProduct);
                }
            }
            setCartItemCount(productsDistinct.length);    
        }
        else {
            setCartItemCount(0);
        }
    }, [stateUpdateState]);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            axiosHttp('post', '/product/countByFavoriteOnRack', true, {})
            .then(res => {
                setFavoriteItemCount(res.data.count);
            });    
        }
        else {
            let favorites = localStorage.getItem('favorites');
            if (favorites === null) {
                setFavoriteItemCount(0);
            }
            else {
                let fav_arr = localStorage.getItem('favorites').split('@');
                setFavoriteItemCount(fav_arr.length);
            }
        }
    }, [stateUpdateFavorite]);

    // useEffect(() => {
    //     clearTimeout(refTimeoutId.current);
    //     refTimeoutId.current = setTimeout(() => {
    //         getMessageData();
    //     }, 5000);
    // }, [stateMessageList]);

    const handleDrawerToggle = () => {
        setViewDrawer((prev) => { 
            return !prev;
        });
    };

    const onCloseClickedHandler = () => {
        setViewDrawer(false);
    };

    const handleClickCategory = (id) => {
        // if vip then just go to vip section
        if (id === 'vip') {
            onCloseClickedHandler();
            history.push('/category/vip');
            return;
        }

        var selectedItem = props.reduxListCategory.filter(el => el.id === id)[0];
        if (selectedItem.expandable) {
            props.onHandleClickCategory(id);
        }
        else {
            onCloseClickedHandler();
            history.push('/category/' + id);
        }
    };

    const handleClickBreadcrumb = (item) => {
        props.onHandleClickBreadcrumb(item.idCategory);
        setViewDrawer(true);
    };

    const handleClickLang = () => {
        if (localStorage.getItem('token')) {
            let tokenDecoded = jwtDecode(localStorage.getItem('token'));
            axiosHttp('post', '/user/update', true, {
                idUser: tokenDecoded.id,
                reason: 'ecom-lang-update',
                lang: props.lang === 'en' ? 'zh' : 'en',
            });
        }
        props.onToggleLang();
    };

    const handleClickLogOut = () => {
        props.onLogOut();
        setCartItemCount(0);
        setFavoriteItemCount(0);
        history.push('/');
    };

    const handleUpdateCart = () => {
        setUpdateState(prev => {
            let newVal = ++prev;
            return newVal;
        });
    };

    const handleUpdateFavorite = () => {
        setUpdateFavorite(prev => {
            let newVal = ++prev;
            return newVal;
        });
        NotificationManager.info(props.lang === 'en' ? 'Update OK' : '更新 OK', null, 1000);
    };

    const handleClickMessageLauncher = () => {
        window.open("https://line.me/R/ti/p/@lgl2164v", "_blank");
        // window.FB.CustomerChat.showDialog();
        // setMessageOpen(prev => {
        //     if (!prev) {
        //         console.log('previously closed');
        //         setMessageNewCount(0);
        //     }
        //     return !prev;
        // });
    };

    // const handleMessageWasSent = (message) => {
    //     let content = '';
    //     if (message.data.text) {
    //         content = message.data.text;
    //     }
    //     if (message.data.emoji) {
    //         content = message.data.emoji;
    //     }
    //     let now = new Date().toISOString().split('.')[0].replace(/T/g, ' ');
    //     message.time = parseUTCStrToLocalStr(now).substr(0, 16);
    //     message.timeUTC = now;

    //     axiosHttp('post', '/html/insertHtml', true, {
    //         idClient: props.idUser,
    //         type: 'client_chat',
    //         content: content,
    //     })
    //     .then((res) => {
    //         if (res.data.message && res.data.message === "COMPLETED") {
    //             setMessageList(prev => {
    //                 return [...prev, message];
    //             });
    //         }
    //     });
    // };

    let routes = (
        <Switch>
            <Route path='/forgotpassword' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><ForgotPassword 
                lang={ props.lang }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/registerPolicy' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><RegisterPolicy 
                lang={ props.lang }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/productSearch' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><ProductList 
                idUser={ props.idUser }
                lang={ props.lang }
                isVIP={ props.isVIP }
                drawerToggleClicked={ handleDrawerToggle }
                breadcrumbClicked={ handleClickBreadcrumb }
                onUpdateCart={ handleUpdateCart }
                onUpdateFavorite={ handleUpdateFavorite }
            /></Suspense>) } />

            <Route path='/payComplete' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><PayComplete 
                lang={ props.lang }
                onUpdateCart={ handleUpdateCart }
            /></Suspense>) } />
            <Route path='/PayCompleteSinopac' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><PayCompleteSinopac 
                lang={ props.lang }
                onUpdateCart={ handleUpdateCart }
            /></Suspense>) } />
            <Route path='/PaySinopac' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><PaySinopac 
                lang={ props.lang }
                onUpdateCart={ handleUpdateCart }
            /></Suspense>) } />

            <Route path='/favorite' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><FavoriteList 
                idUser={ props.idUser }
                lang={ props.lang }
                isVIP={ props.isVIP }
                onUpdateFavorite={ handleUpdateFavorite }
                onUpdateCart={ handleUpdateCart }
            /></Suspense>) } />
            <Route path='/purchased' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><PurchasedList 
                lang={ props.lang }
                idUser={ props.idUser }
                isVIP={ props.isVIP }
                onUpdateFavorite={ handleUpdateFavorite }
            /></Suspense>) } />
            <Route path='/category/:id' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><ProductList 
                idUser={ props.idUser }
                lang={ props.lang }
                isVIP={ props.isVIP }
                drawerToggleClicked={ handleDrawerToggle }
                breadcrumbClicked={ handleClickBreadcrumb }
                onUpdateCart={ handleUpdateCart }
                onUpdateFavorite={ handleUpdateFavorite }
            /></Suspense>) } />
            <Route path='/register' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><Register 
                lang={ props.lang }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/product/:id/:idVar?' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><ProductSingle 
                lang={ props.lang }
                idUser={ props.idUser }
                isVIP={ props.isVIP }
                onUpdateCart={ handleUpdateCart }
                onUpdateFavorite={ handleUpdateFavorite }
                onClickMessageLauncher={ handleClickMessageLauncher }
                drawerToggleClicked={ handleDrawerToggle }
                breadcrumbClicked={ handleClickBreadcrumb }
            /></Suspense>) } />
            <Route path='/contact' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><Contact 
                lang={ props.lang }
                email={ props.email }
                idUser={ props.idUser }
                onClickLogOut={ handleClickLogOut }
            /></Suspense>) } />
            <Route path='/member' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><MemberList 
                lang={props.lang}
                email={props.email}
                idUser={props.idUser}
                onClickLogOut={handleClickLogOut}
            /></Suspense>) } />
            <Route path='/orders' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><OrderList 
                lang={ props.lang }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/order/:idOrder' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><OrderSingle 
                lang={ props.lang }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/login' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><Login 
                lang={ props.lang }
                onUpdateCart={ handleUpdateCart }
                onUpdateFavorite={ handleUpdateFavorite }
            /></Suspense>) } />
            <Route path='/cart' render={ () => (<Suspense fallback={ <div style={{ height: '400px' }}><Spinner lang={ props.lang } /></div> }><Cart 
                lang={ props.lang }
                isVIP={ props.isVIP }
                idUser={ props.idUser }
                onUpdateCart={ handleUpdateCart }
                drawerToggleClicked={ handleDrawerToggle }
            /></Suspense>) } />
            <Route path='/news/:idNews' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><NewsSingle 
                lang={ props.lang }
            /></Suspense>) } />
            <Route path='/news' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><NewsList 
                lang={ props.lang }
            /></Suspense>) } />
            <Route path='/faq/:slug' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><FaqList 
                lang={ props.lang }
            /></Suspense>) } />
            <Route path='/faq' render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><FaqList 
                lang={ props.lang }
            /></Suspense>) } />
            <Route path='/' exact render={ () => (<Suspense fallback={<div><SpinnerBackdrop lang={ props.lang } /></div>}><Home 
                lang={ props.lang }            
            /></Suspense>) } />
            <Redirect to="/" />
        </Switch>
    );

    return (
        <MainWrapper>
            {showLayout 
            ? (
                <React.Fragment>
                <Header 
                    drawerToggleClicked={ handleDrawerToggle } 
                    onClickLang={ handleClickLang }
                    onClickLogOut={ handleClickLogOut }
                    lang={ props.lang }
                    email={ props.email }
                    isVIP={ props.isVIP }
                    cartItemCount={ stateCartItemCount }
                    favoriteItemCount={ stateFavoriteItemCount }
                />
                <Navbar 
                    drawerToggleClicked={ handleDrawerToggle } 
                    lang={ props.lang }
                />
                <SideDrawer 
                    data={ props.reduxListCategory }
                    lang={ props.lang }
                    open={ stateViewDrawer } 
                    onCloseClicked={ onCloseClickedHandler }
                    onClickCategory={ handleClickCategory }
                />
                </React.Fragment>
            ) 
            : null}
            {/* { props.idUser ? (
                <Launcher
                    agentProfile={{
                        teamName: props.lang === 'en' ? HtmlParser('LIVE CHAT<br>Mon-Fri 9:00-17:00 (GMT+8)<br>Excluding Holidays') : HtmlParser('線上客服<br>週一至週五 9:00-17:00 (GMT+8)<br>假日休息'),
                    }}
                    onMessageWasSent={ (message) => handleMessageWasSent(message) }
                    messageList={ stateMessageList }
                    newMessagesCount={ stateMessageNewCount }
                    showEmoji={ false }
                    showFilePicker={ false }
                    isOpen={ stateMessageOpen }
                    handleClick={ () => handleClickMessageLauncher() }
                    mute={ true }
                />
            ) : null } */}
            <Content>
                { routes }
            </Content>
            {showLayout 
            ? (
                <Footer 
                    idUser={ props.idUser }
                    drawerToggleClicked={ handleDrawerToggle }
                    lang={ props.lang }
                    isVIP={ props.isVIP }
                    cartItemCount={ stateCartItemCount }
                    // messageNewCount={ stateMessageNewCount }
                    // onClickMessageLauncher={ handleClickMessageLauncher }
                    favoriteItemCount={ stateFavoriteItemCount }
                />
            ) 
            : null}
            <NotificationContainer />
        </MainWrapper>
    );
};

const mapStateToProps = state => {
    return {
        idUser: state.auth.idUser,
        email: state.auth.email,
        isVIP: state.auth.isVIP,
        authRedirectPath: state.auth.authRedirectPath,
        reduxListCategory: state.category.listCategory,
        lang: state.auth.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignin: () => dispatch(actions.checkAuthState()),
        onUpdateListCategory: () => dispatch(actions.updateListCategory()),
        onHandleClickCategory: (id) => dispatch(actions.clickListCategory(id)),
        onHandleClickBreadcrumb: (id) => dispatch(actions.clickBreadcrumbCategory(id)),
        onToggleLang: () => dispatch(actions.toggleLang()),
        onInitLang: () => dispatch(actions.initLang()),
        onLogOut: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainReactObject);

const sort_messageList = (a, b) => {
    if (a.timeUTC > b.timeUTC) { return 1; }
    if (a.timeUTC < b.timeUTC) { return -1; }
    return 0;
};

const MainWrapper = styled.div`
    width: 100%;
`;

const Content = styled.main`
    margin-top: 56px;

    @media (min-width: 1000px) {
        margin-top: 0px;
    }
`;
