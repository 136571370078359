import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { urlBaseImgStatic } from '../../helpers/url';

const Logo = () => {
    let history = useHistory();

    const handleClickLogo =() => {
        // history.push('/');
        window.location.href = '/';
    };

    return (
        <React.Fragment>
            <LogoMobile onClick={ handleClickLogo }>
                <img src={urlBaseImgStatic + 'logo/WW-new-08.png' } style={{width:'100%', paddingTop:'0.5rem'}} alt="光淙金工購物網" />
                {/* <img src={ urlBaseImgStatic + 'logo/WW_340x340.png' } style={{ width: '100%', }} alt="光淙金工購物網" /> */}
            </LogoMobile>
            <LogoDesktop onClick={handleClickLogo} src={urlBaseImgStatic + 'logo/WW-new-07.png'} style={{marginTop:'0.25rem', marginLeft:'0.5rem'}} alt="光淙金工購物網" />
            {/* <NavLink to="/"><img className={ classes.Logo } src={ urlBaseImgStatic + 'logo/WW_title_235x75.png' } alt="光淙金工購物網" /></NavLink> */}
        </React.Fragment>
    );
};

export default Logo;

const LogoDesktop = styled.img`
    display: none;

    @media (min-width: 1000px) {
        display: block;
        width: auto;
        height: 57px;
    }
`;

const LogoMobile = styled.div`
    display: block;
    width: 300px;
    height: 50px;
    // width: 40px;
    // height: 40px;

    @media (min-width: 1000px) {
        display: none;
    }
`;

