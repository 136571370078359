import axios from 'axios';
import { DateTime } from 'luxon';

import { urlHostRest } from './url';

// str vars need to be in ISO format ie. yyyy-mm-ddThh:mm:ss.mmm{zone}
export const convertTimeISOStrTimezone = (timeStr, timezone) => {
    let timeObj = DateTime.fromISO(timeStr);
    let timeObjNew = timeObj.setZone(timezone);
    return timeObjNew.toString();
};

export const check_valid_email = (input) => {
    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input);
};

export const check_valid_id_tax_business_tw = (input) => {
    if (isNaN(input) || input.length !== 8) {
        return false;
    }
    return true;
};

export const check_valid_phone_mail_tw = (input) => {
    // refer to https://zh.wikipedia.org/wiki/中華民國長途電話區號表
    switch (input.substr(0, 2)) {
        case '02':
            if (input.length !== 10) {
                return false;
            }
        break;
        case '03':
        case '05':
        case '06':
        case '07':
        case '08':
            if (input.length !== 9) {
                return false;
            }
        break;
        case '04':
            if (input.substr(0, 3) === '043') {
                if (input.length !== 10) {
                    return false;
                }    
            }
            else {
                if (input.length !== 9) {
                    return false;
                }
            }
        break;
        default:
            return false;
        }
        return true;
};

export const check_valid_phone_mobile_tw = (input) => {
    if (input.indexOf('09') !== 0 || input.length !== 10) {
        return false;
    }
    return true;
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const axiosHttp = async (method, url, tokenAuth, data) => {
    let headers = {};
    headers['Content-Type'] = 'application/json';
    if (tokenAuth) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    return axios({
        method: method,
        url: urlHostRest + url,
        headers: headers,
        data: data,
    })
    .then(res => {
        return res;
    })
    .catch(err => {
        axiosCatch(err);
    });    
};

export const axiosCatch = (err, msg) => {
    console.log(err);
    console.log(msg);
    // console.log(err.response.data.message);
    if (err.response) {
        if (err.response.data.message === 'jwt expired') {
            alert('Login Expired');
            window.location.href = '/';
        }
        else if (err.response.data.status === 500) {
            if (err.response.data.message == 'jwt malformed') {
                alert('ERROR ' + err.response.data.status + " : 尚未登入，請重新登入 (Please log in first)");
                window.location.href = '/';
                return;
            }
            alert('ERROR ' + err.response.data.status + " : " + err.response.data.message);
        }
        else if (err.response.data.status === 422) {
            let message = err.response.data.message + '\n';
            for (var key in err.response.data.data) {
                message += '  ' + err.response.data.data[key].msg + '\n';
            }
            alert('ERROR ' + err.response.data.status + " :\n" + message)
        }
    }
    else {
        // window.location.href = '/';
    }
};

export const numberWithCommas = (x) => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const convertDateToList = (INPUT_DATE) => {
    let RETURN_LIST = [];
    if (INPUT_DATE) {
        RETURN_LIST.push(INPUT_DATE.getFullYear());
        RETURN_LIST.push(("0" + (INPUT_DATE.getMonth() + 1)).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getDate()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getHours()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getMinutes()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getSeconds()).slice(-2));    
    }
    return RETURN_LIST;
}

export const convertUTCDateToList = (INPUT_DATE) => {
    let RETURN_LIST = [];
    if (INPUT_DATE) {
        RETURN_LIST.push(INPUT_DATE.getUTCFullYear());
        RETURN_LIST.push(("0" + (INPUT_DATE.getUTCMonth() + 1)).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getUTCDate()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getUTCHours()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getUTCMinutes()).slice(-2));
        RETURN_LIST.push(("0" + INPUT_DATE.getUTCSeconds()).slice(-2));    
    }
    return RETURN_LIST;
}

export const convertStringToDate = (INPUT_STR) => {
    if (INPUT_STR) {
        let INPUT_LIST = INPUT_STR.split('-');
        return new Date(INPUT_LIST[0], Number(INPUT_LIST[1]) - 1, INPUT_LIST[2]);    
    }
}

export const convertStringToDateUTC = (INPUT_STR) => {
    if (INPUT_STR) {
        let INPUT_LIST = INPUT_STR.split('-');
        return new Date(Date.UTC(INPUT_LIST[0], Number(INPUT_LIST[1]) - 1, INPUT_LIST[2]));    
    }
}

export const parseUTCStrToLocalStr = (strUTC) => {
    let [dateFull, timeFull] = strUTC.split(' ');
    let dateYear = typeof dateFull.split('-')[0] === 'undefined' ? '' : dateFull.split('-')[0];
    let dateMonth = typeof dateFull.split('-')[1] === 'undefined' ? '' : parseInt(dateFull.split('-')[1]) - 1;
    let dateDay = typeof dateFull.split('-')[2] === 'undefined' ? '' : dateFull.split('-')[2];
    let timeHour = typeof timeFull.split(':')[0] === 'undefined' ? '' : timeFull.split(':')[0];
    let timeMinute = typeof timeFull.split(':')[1] === 'undefined' ? '' : timeFull.split(':')[1];
    let timeSecond = typeof timeFull.split(':')[2] === 'undefined' ? '' : timeFull.split(':')[2];
    let dateLocal = new Date(Date.UTC(dateYear, dateMonth, dateDay, timeHour, timeMinute, timeSecond));
    let listLocal = convertDateToList(dateLocal);
    return listLocal[0] + '-' + listLocal[1] + '-' + listLocal[2] + ' ' + listLocal[3] + ':' + listLocal[4] + ':' + listLocal[5];
};

export const nameVendorFromIdVendor = (idVendor, listVendor) => {
    for (let a in listVendor) {
        if (listVendor[a].idVendor.toString() === idVendor.toString()) {
            return {
                'zh': listVendor[a].nameVendorZh,
                'en': listVendor[a].nameVendorEn,
            };
        }
    }
};

export const addMonthsToStr = (inputStr, numMonths) => {
    let time = new Date(inputStr);
    time.setMonth(time.getMonth() + Number(numMonths));

    return time.getFullYear() + "-" + ('0' + (time.getMonth() + 1)).slice(-2) + "-" + ('0' + time.getDate()).slice(-2) + " " + ('0' + time.getHours()).slice(-2) + ":" + ('0' + time.getMinutes()).slice(-2) + ":" + ('0' + time.getSeconds()).slice(-2);
};

export const addMonthsToTime = (inputTime, numMonths) => {
    let time = inputTime;
    time.setMonth(time.getMonth() + Number(numMonths));

    return time;
};