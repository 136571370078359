import React from 'react';
import { connect } from 'react-redux';

import classes from './SideDrawer.module.css';

import Backdrop from '../UI/Backdrop/Backdrop';
import Category from './Category';
import { urlBaseImgStatic } from '../../helpers/url';

const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    let data = [];
    if (props.data) {
        data = JSON.parse(JSON.stringify(props.data));
        data.unshift({
            expandable: false,
            expanded: false,
            id: 'vip',
            idParent: 0,
            level: 0,
            show: true,
            titleEn: 'VIP Discount',
            titleZh: 'VIP 優惠專區',
        });
    }
    data.push({
        expandable: false,
        expanded: false,
        id: 'faq',
        idParent: 0,
        level: 0,
        show: true,
        titleEn: 'FAQ',
        titleZh: 'FAQ',
    });

    let categorys = null;
    if (data) {
        categorys = Object.keys(data)
        .map(key => {
            return (
                <Category 
                    key={ data[key].id }
                    id={ data[key].id }
                    parent_id={ data[key].idParent }
                    title={ props.lang === 'en' ? data[key].titleEn : data[key].titleZh } 
                    show={ data[key].show }
                    expandable={ data[key].expandable }
                    expanded={ data[key].expanded } 
                    level={ data[key].level }
                    onClickCategory={ props.onClickCategory }
                />
            );
        })
        .reduce((arr, el) => {
            return arr.concat(el);
        }, []);    
    }
    
    return (
        <React.Fragment>
            <Backdrop show={ props.open } clicked={ props.onCloseClicked } />
            <div className={ attachedClasses.join(' ') }>
                <header className={ classes.Header }>
                    <img src={ urlBaseImgStatic + 'logo/WW_340x340.png' } style={{ width: '40px', height: '40px', }} alt="光淙金工購物網" />
                    <div style={{ fontSize: '1.5rem', color: 'white', margin: '0px 15px', }}>商品分類</div>
                </header>
                <div style={{ overflow: 'auto', height: '91%' }}>
                    { categorys }
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        reduxListCategory: state.category.listCategory,
    }
};

export default connect(mapStateToProps)(SideDrawer);