import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../helpers/base';

const initialState = {
    token: null,
    idUser: null,
    email: null,
    isVIP: null,
    error: null,
    loading: false,
    authRedirectPath: '',
    lang: 'zh',
};

const authResetError = (state, action) => {
    return updateObject(state, {
        error: null,
    });
};

const authStart = (state, action) => {
    return updateObject(state, { 
        error: null, 
        loading: true, 
    });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        idUser: action.idUser,
        email: action.email,
        isVIP: action.isVIP,
        error: null,
        loading: false,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        token: null,
        idUser: null,
        email: null,
        isVIP: null,
        error: action.error,
        loading: false,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        idUser: null,
        email: null,
        isVIP: null,
        error: null,
        loading: false,
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.path,
    });
};

const setLang = (state, action) => {
    return updateObject(state, {
        lang: action.lang,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_SET_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_RESET_ERROR: return authResetError(state, action);
        case actionTypes.AUTH_SET_LANG: return setLang(state, action);
        default: return state;
    };
};

export default reducer;