// props.drawerToggleClicked={ handleDrawerToggle } 
// props.onClickLang={ handleClickLang }
// props.onClickLogOut={ handleClickLogOut }
// props.lang={ props.lang }
// props.email={ props.email }
// props.isVIP={ props.isVIP }
// props.cartItemCount={ stateCartItemCount }

import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import Logo from './Logo';
import Search from './Search';
import HeaderRight from './HeaderRight';

const MainReactObject = (props) => {
    let history = useHistory();

    const handleSubmitSearchProduct = (query) => {
        if (query.trim() !== '') {
            history.push('/productSearch?q=' + query);
        }
    };

    return (
        <React.Fragment>
            <Header>
                <Logo />
                <Search 
                    lang={ props.lang } 
                    onSubmitSearch={ handleSubmitSearchProduct }
                    placeholderZh='產品搜尋'
                    placeholderEn='Product Search'
                />
                <HeaderRight 
                    onClickLang={ props.onClickLang } 
                    onClickLogOut={ props.onClickLogOut }
                    lang={ props.lang } 
                    email={ props.email }
                    isVIP={ props.isVIP }
                    cartItemCount={ props.cartItemCount }
                    favoriteItemCount={ props.favoriteItemCount }
                />
            </Header>
            <HeaderMobile>
                <Logo />
                <Search 
                    lang={ props.lang } 
                    onSubmitSearch={ handleSubmitSearchProduct }
                    placeholderZh='產品搜尋'
                    placeholderEn='Product Search'
                />
                <FontAwesomeIcon icon={ faLanguage } size="2x" onClick={ props.onClickLang } />
            </HeaderMobile>
        </React.Fragment>
    );
};

export default MainReactObject;

const Header = styled.header`
    display: none;

    @media (min-width: 1000px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        height: 65px;
    }
`;

const HeaderMobile = styled.header`
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--wnwBlue);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    padding-left: 13px;
    box-sizing: border-box;
    z-index: 90;    

    svg {
        color: white;
    }

    @media (min-width: 1000px) {
        display: none;
    }
`;