import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import classes from './SpinnerBackdrop.module.css';

import Backdrop from '../Backdrop/Backdrop';

const spinner = (props) => {
    return (
        <React.Fragment>
            <Backdrop show={ true } />
            <div className={ classes.Loader }></div>
            <div className={ classes.Text }>{ ReactHtmlParser(props.lang === 'en' ? 'Loading...' : '&nbsp;&nbsp;處理中...') }</div>
        </React.Fragment>
    );
};

export default spinner;