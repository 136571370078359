// props.idUser={ props.idUser }
// props.drawerToggleClicked={ handleDrawerToggle }
// props.lang={ props.lang }
// props.isVIP={ props.isVIP }
// props.cartItemCount={ stateCartItemCount }
// props.messageNewCount={ stateMessageNewCount }
// props.onClickMessageLauncher={ handleClickMessageLauncher }


import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHeart, faShoppingCart, faUserCircle, faNewspaper, faSmile, faComment } from '@fortawesome/free-solid-svg-icons';
import { faLine, faFacebookSquare, faYoutubeSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons';
// import { faSmile } from '@fortawesome/free-regular-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import classes from './Footer.module.css';

import Modal from '../../components/UI/Modal/Modal';
import { axiosHttp } from '../../helpers/base';
import { urlBaseImgStatic } from '../../helpers/url';

const MainReactObject = (props) => {
    let history = useHistory();

    const [stateModalShow, setModalShow] = useState(false);
    const [stateModalContent, setModalContent] = useState(null);

    const now_local = new Date();
    const now_tw = new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(), now_local.getUTCHours() + 8, now_local.getUTCMinutes(), now_local.getUTCSeconds());
    const timeString = (now_tw.getFullYear() + "-" + ("0" + (now_tw.getMonth() + 1)).slice(-2) + "-" + ("0" + now_tw.getDate()).slice(-2) + " " + ("0" + now_tw.getHours()).slice(-2) + ":" + ("0" + now_tw.getMinutes()).slice(-2) + ":" + ("0" + now_tw.getSeconds()).slice(-2)).substr(0, 16);
    const currentTime = useState(timeString);
    setInterval(() => {
        const now_local = new Date();
        const now_tw = new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(), now_local.getUTCHours() + 8, now_local.getUTCMinutes(), now_local.getUTCSeconds());
        const timeString = (now_tw.getFullYear() + "-" + ("0" + (now_tw.getMonth() + 1)).slice(-2) + "-" + ("0" + now_tw.getDate()).slice(-2) + " " + ("0" + now_tw.getHours()).slice(-2) + ":" + ("0" + now_tw.getMinutes()).slice(-2) + ":" + ("0" + now_tw.getSeconds()).slice(-2)).substr(0, 16);
        currentTime[1](timeString);
    }, 5000);

    const handleClickNews = () => {
        history.push('/news');
    };

    const handleClickFavorite = () => {
        history.push('/favorite');
    };

    const handleClickCart = () => {
        history.push('/cart');
    };

    const handleClickMember = () => {
        history.push('/member');
    };

    const handleClickAboutUs = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readAboutUsByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'About Us' : '關於我們') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handleClickPrivacyPolicy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readPrivacyPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Privacy Policy' : '隱私權條款') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handleClickVIPPolicy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readVIPPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Become VIP' : '成為VIP') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handleClickReturnPolicy = () => {
        setModalContent(null);
        axiosHttp('post', '/var/readReturnPolicyByType', false, {
            type: 'content-' + props.lang
        })
        .then((res) => {
            if (res.data.results) {
                let html = '<h2 style="margin-top:0.5rem; margin-bottom:0.5rem;">' + (props.lang === 'en' ? 'Warranty & Return Policy' : '保固及退換貨須知') + '</h2><hr>';
                html += res.data.results[0].F16;
                setModalContent(ReactHtmlParser(html));
                setModalShow(true);
            }
        });
    };

    const handleClickShowMap = () => {
        let html = '<iframe style="width:700px; height:525px; border:0;" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCIzP45FPY_vzN7l_c4q8ZF60hanhuRnRg&q=光淙金工"></iframe>';
        setModalContent(ReactHtmlParser(html));
        setModalShow(true);
    };

    const handleClickShowDirections = () => {
        let html = '<img src="' + urlBaseImgStatic + 'misc/directions-zh.jpg"/>';
        setModalContent(ReactHtmlParser(html));
        setModalShow(true);
    };

    const handleClickShowShippingFees = () => {
        let html = '<img src="' + urlBaseImgStatic + 'misc/2022運費辦法-01.jpg"/>';
        setModalContent(ReactHtmlParser(html));
        setModalShow(true);
    };

    let userIcon = <FontAwesomeIcon icon={ faUserCircle } size="lg" />;
    if (props.isVIP) {
        userIcon = (
            <FontAwesomeIcon icon={ faSmile } size="lg" />
        )
    }

    return (
        <React.Fragment>
        <Modal
            show={ stateModalShow }
            closeByBackdropClick={ true }
            onClickModalClose={ () => setModalShow(false) }
        >{ stateModalContent }</Modal>

        <FooterMobile>
            <div onClick={ props.drawerToggleClicked }>
                <FontAwesomeIcon icon={ faBars } size="lg" />
                <div>{ props.lang === 'en' ? 'Categories' : '產品分類' }</div>
            </div>
            <div onClick={ handleClickNews }>
                <FontAwesomeIcon icon={ faNewspaper } size="lg" />
                <div>{ props.lang === 'en' ? 'Tips Sharing' : '技術分享' }</div>
            </div>
            <div onClick={ handleClickFavorite }>
                <FontAwesomeIcon icon={ faHeart } size="lg" />
                <div>{ props.lang === 'en' ? 'Favs' : '最愛' }{ props.favoriteItemCount === 0 ? null : ' (' + props.favoriteItemCount + ')' }</div>
            </div>
            <div onClick={ handleClickCart }>
                <FontAwesomeIcon icon={ faShoppingCart } size="lg" />
                <div>{ props.lang === 'en' ? 'Cart' : '購物車' }{ props.cartItemCount === 0 ? null : ' (' + props.cartItemCount + ')' }</div>
            </div>
            {/* { props.idUser ? (
                <div onClick={ props.onClickMessageLauncher }>
                    <FontAwesomeIcon icon={ faComment } size="lg" />
                    <div>{ props.lang === 'en' ? 'Live' : '客服' }{ props.messageNewCount === 0 ? null : ' (' + props.cartItemCount + ')' }</div>
                </div>
            ) : null } */}
            <div onClick={ handleClickMember }>
                { userIcon }
                <div>{ props.lang === 'en' ? 'Member' : '會員' }</div>
            </div>
        </FooterMobile>


        <div style={{ backgroundColor: '#043A55' }}>
            <Footer>
                <table style={{width:'100%'}}>
                    <tbody>
                        <tr>
                            <td>{props.lang === 'en' ? 'Address' : '門市地址'}</td>
                            <td>|</td>
                            <td colSpan={2}><a href='javascript:void(0)' onClick={handleClickShowMap}>{props.lang === 'en' ? 'No.104, Sec.1, Fenliao Rd., Linkou Dist., New Taipei City, 244012, Taiwan(R.O.C)' : '244012台灣新北市林口區粉寮路一段104號'}</a></td>
                        </tr>
                        <tr>
                            <td>{props.lang === 'en' ? 'Hours' : '營業時間'}</td>
                            <td>|</td>
                            <td>{props.lang === 'en' ? 'Monday ~ Friday 08:00 - 17:00' : '週一 ～ 週五 08:00 - 17:00'}</td>
                            <td rowSpan={2} style={{textAlign:'right'}}>
                                <a href="https://line.me/R/ti/p/@lgl2164v" target="_blank"><FontAwesomeIcon icon={faLine} inverse size="3x" /></a>
                                <a href="https://instagram.com/wwcoinc" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} inverse size="3x" style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} /></a>
                                <a href="https://www.facebook.com/950.com.tw/" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} inverse size="3x" style={{marginRight:'0.5rem'}} /></a>
                                <a href="https://www.youtube.com/channel/UCmeV0VqxtCiqwI3K4W6tHQA" target="_blank"><FontAwesomeIcon icon={faYoutubeSquare} inverse size="3x" /></a>
                            </td>
                        </tr>
                        <tr>
                            <td>{props.lang === 'en' ? 'Email' : '客服信箱'}</td>
                            <td>|</td>
                            <td><a href="mailto:shop@wwco.com.tw?Subject=950.com.tw%20-%20">shop@wwco.com.tw</a></td>
                        </tr>
                        <tr>
                            <td>{props.lang === 'en' ? 'Service' : '客服電話'}</td>
                            <td>|</td>
                            <td><a href="tel:+886-2-2601-7601">+886-2-2601-7601</a></td>
                            <td style={{textAlign:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={() => handleClickAboutUs()}>{props.lang === 'en' ? 'About Us' : '關於我們'}</span>
                                {props.lang === 'en' ? null : (<React.Fragment>&nbsp;&nbsp;/&nbsp;&nbsp;<span style={{cursor: 'pointer'}} onClick={handleClickShowDirections}>交通資訊</span></React.Fragment>)}
                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                <span style={{cursor:'pointer'}} onClick={() => handleClickPrivacyPolicy()}>{props.lang === 'en' ? 'Privacy Policy' : '隱私權條款' }</span>
                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                <span style={{cursor:'pointer'}} onClick={() => handleClickVIPPolicy()}>{props.lang === 'en' ? 'Become VIP' : '成為VIP'}</span>
                                {props.lang === 'en' ? null : (<React.Fragment>&nbsp;&nbsp;/&nbsp;&nbsp;<span style={{cursor: 'pointer'}} onClick={handleClickShowShippingFees}>運費說明</span></React.Fragment>)}
                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                <span style={{cursor:'pointer'}} onClick={() => handleClickReturnPolicy()}>{props.lang === 'en' ? 'Warranty & Return Policy' : '保固及退換貨須知'}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{props.lang === 'en' ? 'Fax' : '傳真FAX'}</td>
                            <td>|</td>
                            <td>+886-2-2601-7600</td>
                            <td style={{textAlign:'right', fontStyle:'italic', fontSize:'0.8rem'}}>
                                Copyright &copy; W & W CO., INC. Taiwan All rights Reserved
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Footer>
        </div>
        </React.Fragment>
    );
};

export default MainReactObject;

const Footer = styled.div`
    display: none;

    a {
        color: var(--wnwOrange);
        text-decoration: none;
    }

    @media (min-width: 1000px) {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        color: white;
        width: 1200px;
        margin: 0 auto;
        padding: 0.5rem;
    }
`;

const FooterMobile = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--wnwBlue);
    color: white;
    width: 100%;
    height: 45px;
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 2;

    /* this is for the wording under the icon */
    div div {
        display: block;
        font-size: 0.8rem;
    }

    a {
        color: var(--wnwOrange);
        text-decoration: none;
    }

    @media (min-width: 1000px) {
        display: none;
    }
`;

