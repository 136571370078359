export const urlHostRest = 'https://www.950tools.com:6302';
export const urlHostEcom = 'https://www.950tools.com';

export const urlBaseImgStatic = 'https://storage.googleapis.com/cdnwnw/prod/img/';
// export const urlBaseImgStatic = 'https://storage.googleapis.com/cdnhardcorptw/eme/img/';

export const timezoneOffsetMinutes = 480;

export const gaPageviewTrackingId = 'UA-211676678-1';

export const pusher_key = '1b63e2370008eb49846f';
export const pusher_cluster = 'ap3';

export const sinopacPayUrl = 'https://epos.sinopac.com/HPPRequest';
export const sinopacPayCompleteHost = 'https://backops.950tools.com:6302';
